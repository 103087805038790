import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, FormHelperText, FormLabel, MenuItem, Select, useTheme } from '@mui/material';
import { store } from '@store/index';

import { selectErrors } from '../../helpers';
import { editableCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { FormControlLoader } from '../FormControlLoader';

type OOSelectProps = {
  isEditableCandidate?: boolean;
  isEditableRecruiter?: boolean;
  validationKey: string;
  label: string;
  description: string;
  menuItems?: JSX.Element[];
  mandatory: boolean;
  loading: boolean;
  rootProps?: any;
  defaultValue?: any;
  onChangeCallback?: (event: any) => void;
  multiple?: boolean;
};

export const OOSelect: React.FC<OOSelectProps> = (props) => {
  const {
    isEditableCandidate,
    isEditableRecruiter,
    label,
    mandatory,
    menuItems,
    description,
    loading,
    validationKey,
    defaultValue,
    onChangeCallback,
    multiple,
    rootProps,
  } = props;
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = defaultValue
    ? { ...formik.getFieldProps(validationKey), defaultValue }
    : formik.getFieldProps(validationKey);

  const fieldMeta = formik.getFieldMeta(validationKey);
  const flowData = store.getState().oneOnboarding.flow;
  const [open, setOpen] = useState<boolean>(false);
  const helperText = fieldMeta && fieldMeta.touched && fieldMeta.error;
  let prefix = label.split('.')[0].toLowerCase();
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isDisabled = !(
    flowWrapper?.isControlEditable(isEditableCandidate ?? true, isEditableRecruiter ?? true) ?? true
  );
  const isEditable = editableCondition(rootProps, flowWrapper, formik.values) ?? true;

  const { t, translationsLoading } = useT('entry', 'approval', 'hiring');
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const items = useMemo(() => {
    const output = [...(menuItems || [])];
    // TODO: migration to mui 5 issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (theme.props?.MuiFormControl?.variant === 'standard' && !fieldProps.value) {
      output.unshift(
        <MenuItem value="" disabled>
          {t(`${prefix}:${label}`)}
        </MenuItem>,
      );
    }
    return output;
  }, [fieldProps.value, label, menuItems, prefix, t, theme]);

  useEffect(() => {
    if (defaultValue !== undefined && !formik.values[validationKey]) {
      formik.setFieldValue(validationKey, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (translationsLoading || loading) {
    return <FormControlLoader />;
  }

  const defaultEmptyValue = multiple ? [] : '';

  const value = formik.values[validationKey] || defaultEmptyValue;

  return (
    <FormControl>
      <Box sx={{ paddingBottom: '0; !important' }}>
        <FormLabel disabled={isDisabled}>
          {t(`${prefix}:${label}`)}
          {mandatory && ' *'}
        </FormLabel>
      </Box>
      <Select
        {...fieldProps}
        {...selectErrors(fieldMeta)}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={(event) => {
          if (open) {
            formik.setFieldValue(validationKey, event.target.value);
          }
          if (onChangeCallback) {
            onChangeCallback(event);
          }
        }}
        value={value}
        label={t(`${prefix}:${label}`)}
        displayEmpty={true}
        style={{
          color:
            //  TODO: migration to mui 5 issue
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            theme.props?.MuiFormControl?.variant === 'standard' && !fieldProps.value
              ? theme.palette.text.disabled
              : undefined,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              overflowX: 'auto',
              maxWidth: 'calc(10% - 32px)', // fix the select width as MUI is using popover
            },
          },
        }}
        disabled={isDisabled || !isEditable}
        multiple={multiple}
      >
        {items}
      </Select>
      {helperText && (
        <FormHelperText error>
          {validationKey === 'documentType' ? t(`documents:validations.chooseOptionList`) : helperText}
        </FormHelperText>
      )}
      {description && <FormHelperText>{t(`${prefix}:${description}`)}</FormHelperText>}
    </FormControl>
  );
};
