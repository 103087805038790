import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTop,
  Dropdown,
  DropdownButton,
  DropdownMenu,
  Notification,
  NotificationBody,
} from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { Link, LinkSkeleton } from '@features/oneOnboarding/controls/fields/Link';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import ActionHistory from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/ActionHistory';
import { DeleteCandidateDialog } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/DeleteCandidateDialog';
import { useUserInfo } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/hooks';
import { getSeverityDetails } from '@helpers/onBoarding.helper';
import {
  mutateCompleteStep,
  mutateDeleteUser,
  mutateUnRejectUser,
  useGetOnboardingsWithRelationsMultiple,
  useGetUser,
  useGetUserProfile,
  useUserCheck,
  useUserOOFlow,
} from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { useInterval } from '@hooks/useInterval';
import useTelemetry from '@hooks/useTelemetry';
import { IntegrationStateFaq } from '@models/integrationStateFaq.model';
import { OnboardingStatus } from '@models/onboarding.model';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { CheckUser } from '@models/user.model';
import { Box, Dialog, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { frForbiddenDepartments } from '../../../../../constants/frForbiddenDepartments';
import { AamBackendApi } from '../../../../../libs/aamBackendApi';
import { AdminRejectReasonPrompt } from '../../../components/AdminRejectReasonPrompt/AdminRejectReasonPrompt';
import InformationList from './InformationList';
import IntegrationErrorsModal from './IntegrationErrorsModal';
import IntegrationStateModal from './IntegrationStateModal';
import { RetriggerIntegrationDialog } from './RetriggerIntegrationDialog';

import styles from './CandidateInfo.module.scss';

interface CandidateInfoProps {
  flowWrapper: OOFlowWrapper;
}

interface CandidateJourney {
  id: string;
  applicationId: string;
  requisitionId: string;
  siteCode: string;
}

const IgnoreDISYSpecialCaseFromIntegrationState = {
  integrationStateKey: 'updateOnboardingCommand',
  value:
    'Could not handle update onboarding command. The LocalEmployeeId was not provided in the Request. ZNumber cannot be Updated.',
  path: 'onboardingResponseData.data.message',
};

export const CandidateInfo: FC<CandidateInfoProps> = ({ flowWrapper }) => {
  const [candidateJourneys, setCandidateJourneys] = useState<CandidateJourney[]>([]);
  const [selectedCandidateJourneyId, setSelectedCandidateJourneyId] = useState<string | null>(null);
  const [currentBirthDepartment, setCurrentBirthDepartment] = useState<string | ''>('');
  const [selectedCandidateJourney, setSelectedCandidateJourney] = useState<CandidateJourney | null>(null);
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);
  const [actionHistory, setActionHistory] = useState(false);
  const [integrationErrorsModalVisible, setIntegrationErrorsModalVisible] = useState(false);
  const [hasHiringFeature, setHasHiringFeature] = useState(flowWrapper?.steps?.find((x) => x.name === 'HIRE'));
  const [disableReject, setDisableReject] = useState(false);
  const [rejectPrompt, setRejectPrompt] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [resetJourneyUserModal, setResetJourneyUserModal] = useState<boolean>(false);
  const [skipDeletionMail, setSkipDeletionMail] = useState<boolean>(false);
  const [integrationStateFaq, setIntegrationStateFaq] = useState<IntegrationStateFaq[] | null>(null);
  const [tryCount, setTryCount] = useState<number>(0);
  const [localMoUrlWasUpdated, setLocalMoWasUpdated] = useState<boolean>(false);
  const [showRetriggerDialog, setShowRetriggerDialog] = useState<boolean>(false);
  const [integrationStateError, setIntegrationStateError] = useState<boolean>(false);
  const [isHireButtonDisabled, setIsHireButtonDisabled] = useState<boolean>(false);
  const [onboardingErrorFiltered, setOnboardingErrorFiltered] = useState<any>({});

  const FETCH_INTERVAL_MS = 2 * 1000;
  const MAX_TRY_COUNT = (120 * 1000) / FETCH_INTERVAL_MS;
  const IS_DISY_NOTIFICATION_SHOWN = 'isDisyNotificationShow';

  const aamBackendApi = useInjection(AamBackendApi);
  const appSettings = useSelector((state: RootState) => state.featureConfiguration.appSettings);
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const storeDispatch = useDispatch();
  const history = useHistory();
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { t } = useTranslation(['recruiter', 'control-items', 'integration-state-faq']);
  const queryClient = useQueryClient();
  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { data: user, refetch: refetchUser } = useGetUser(tenantId, userId);
  const { data: userFlowWithCurrentStep } = useUserOOFlow(clientOrganizationId, configurationId, userId, applicationId);
  const { data: userProfile, refetch: refetchUserProfile } = useGetUserProfile(tenantId, userId);
  const [showHireNotification, setShowHireNotification] = useState<boolean>(false);
  const { createTelemetryAuthenticated } = useTelemetry();
  const showUnder18warning =
    userFlowWithCurrentStep?.selectedConfiguration?.additionalConfigurations?.showUnder18warning;
  const hideProfileDeleteButtonRecruiter =
    userFlowWithCurrentStep?.selectedConfiguration?.additionalConfigurations?.hideProfileDeleteButtonRecruiter;

  const hasPdfExport =
    (userFlowWithCurrentStep?.selectedConfiguration?.exports?.filter((x: any) => x.type === 'approved') || []).length >
    0;

  const { data: onboarding, refetch: refetchOnboarding } = useGetOnboardingsWithRelationsMultiple(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
  );

  const filterIntegrationState = useCallback(
    (state: any) => {
      const disySpecialCase = IgnoreDISYSpecialCaseFromIntegrationState;
      const evaluatedValue = lodash.get(state, disySpecialCase.integrationStateKey);
      const hasErrorKey = evaluatedValue === 'error';
      const hasExpectedErrorValue = lodash.get(state, disySpecialCase.path) === disySpecialCase.value;

      if (hasErrorKey && hasExpectedErrorValue) {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [disySpecialCase.integrationStateKey]: _,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [`${disySpecialCase.integrationStateKey}Error`]: __,
          ...rest
        } = state;
        state = rest;
      }

      return state;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onboarding?.integrationState],
  );

  const userCheck: CheckUser | undefined = onboarding
    ? {
        clientOrganizationId: onboarding.clientOrganizationId,
        userId: onboarding.userId,
        tenantId: onboarding.tenantId,
        applicationId: onboarding.applicationId || '',
        configurationId: onboarding.configurationId,
        rejectedAt: onboarding.rejectedAt,
      }
    : undefined;

  const { onboardingChecked, refetchOnboardingCheck } = useUserCheck(onboarding);

  useEffect(() => {
    if (onboarding?.integrationState) {
      setOnboardingErrorFiltered(filterIntegrationState(onboarding?.integrationState));
    }
  }, [filterIntegrationState, onboarding?.integrationState]);

  useEffect(() => {
    setHasHiringFeature(flowWrapper?.steps?.find((x) => x.name === 'HIRE'));
    setDisableReject(
      onboarding?.status === OnboardingStatus.hired ||
        (hasHiringFeature !== undefined &&
          flowWrapper?.steps?.findIndex((s) => s.name === userFlowWithCurrentStep?.currentStep) >=
            flowWrapper?.steps?.findIndex((s) => s.name === OOStepsEnum.recruiterSignDocuments)),
    );
    const bDepartment = flowWrapper.getUserValueOrDefault(['REGION2_OF_BIRTH_2', 'REGION2_OF_BIRTH'], 'string');
    setCurrentBirthDepartment(bDepartment);
    if (userCheck) {
      refetchOnboardingCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowWrapper]);

  const { mutate: deleteUser, isLoading: deleting } = mutateDeleteUser(tenantId, userId, () => {
    refetchUserProfile();
    refetchOnboarding();
    refetchUser();
    setDeleteUserModal(false);
    setSkipDeletionMail(false);
    history.push(`/oo/recruiter/`);
  });

  const { mutate: unRejectUser } = mutateUnRejectUser(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    () => {
      refetchUserProfile();
      refetchOnboarding();
      refetchUser();
    },
  );
  const exportApprovedPDF = async (
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    applicationId: string,
  ) => {
    const resp = await aamBackendApi.exportApprovedPDF(clientOrganizationId, configurationId, userId, applicationId);
    const fileName = resp.headers['content-disposition']?.split('filename=')[1] || 'candidatesList.pdf';
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (!userProfile?.customData?.pdfExported) {
      userProfile!.customData!.pdfExported = true;
      await aamBackendApi.updateUserProfile(tenantId, userId, userProfile);
      refetchUserProfile();
    }
  };

  const exportAllDocumentsPDF = async (userId: string) => {
    const resp = await aamBackendApi.exportAllDocumentsPDF(userId);
    const fileName = resp.headers['content-disposition']?.split('filename=')[1] || 'candidatesList.pdf';
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const { mutateAsync: approveCandidate } = mutateCompleteStep(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    OOStepsEnum.approve,
    queryClient,
    async () => {
      if (hasPdfExport) {
        await exportApprovedPDF(clientOrganizationId, configurationId, userId, applicationId);
        await exportAllDocumentsPDF(userId);
      }
      refetchOnboardingCheck();

      if (themeAdditionalData?.showNotificationOnApprove) {
        storeDispatch(
          snackbarSlice.actions.showSuccess({
            message: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.candidateApproved'),
          }),
        );
      }
    },
  );

  const { mutate: hireCandidate } = mutateCompleteStep(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    OOStepsEnum.hire,
    queryClient,
    () => {
      refetchOnboardingCheck();
    },
  );

  const { phoneNumber, address, dateOfBirth, client, clientDisplayName, journey } = useUserInfo(
    tenantId,
    flowWrapper,
    userProfile,
  );

  const isDisyButtonVisible = onboardingChecked?.hasHiringFeature && onboarding?.localMoUrl;
  const rejectFeatureEnabled = !(
    appSettings?.allowedBulkActions?.length && !appSettings.allowedBulkActions.find((item) => item.name === 'REJECT')
  );
  const visibleButton = {
    localSystem: isDisyButtonVisible,
    unReject: (onboarding?.status === 'rejected' || onboarding?.rejectedAt !== null) && user?.deletedAt === null,
    reject: rejectFeatureEnabled && !(onboarding?.status === 'rejected' || onboarding?.rejectedAt !== null),
  };

  const candidateIsUnder18 = useMemo(() => {
    const eighteenYearsLater = moment(dateOfBirth).add(18, 'years');
    return moment().isBefore(eighteenYearsLater) && showUnder18warning;
  }, [dateOfBirth, showUnder18warning]);

  const pollForDisyLink = useMemo(() => {
    const shouldPoll =
      onboardingChecked?.hasHiringFeature &&
      !onboarding?.localMoUrl &&
      tryCount <= MAX_TRY_COUNT &&
      (userFlowWithCurrentStep?.currentStep as string) === OOStepsEnum.awaitContract;

    if (shouldPoll) {
      localStorage.setItem(IS_DISY_NOTIFICATION_SHOWN, 'false');
    }

    return shouldPoll;
  }, [
    MAX_TRY_COUNT,
    onboarding?.localMoUrl,
    onboardingChecked?.hasHiringFeature,
    tryCount,
    userFlowWithCurrentStep?.currentStep,
  ]);

  useMemo(() => {
    if (isDisyButtonVisible && localStorage.getItem(IS_DISY_NOTIFICATION_SHOWN) === 'false') {
      setShowHireNotification(true);

      localStorage.removeItem(IS_DISY_NOTIFICATION_SHOWN);

      setTimeout(() => {
        setShowHireNotification(false);
      }, 3000);
    }
  }, [isDisyButtonVisible]);

  useEffect(() => {
    const userIntegrationState = onboardingErrorFiltered ?? {};
    const hasIntegrationStateAnyError = Object.values(userIntegrationState).some((val) => val === 'error');
    setIntegrationStateError(hasIntegrationStateAnyError);
  }, [onboarding?.updatedAt, onboarding?.integrationState, onboardingErrorFiltered]);

  useEffect(() => {
    setCandidateJourneys(onboarding?.customData?.journeys ?? []);
    setSelectedCandidateJourneyId(onboarding?.customData?.selectedCandidateJourneyId);
  }, [onboarding]);

  useEffect(() => {
    setSelectedCandidateJourney(candidateJourneys.find((j) => j.id === selectedCandidateJourneyId) || null);
  }, [selectedCandidateJourneyId, candidateJourneys]);

  const onCandidateJourneySelect = (selectedId: string) => {
    if (selectedId) {
      setSelectedCandidateJourneyId(selectedId);

      if (!onboarding?.applicationId) {
        return;
      }

      aamBackendApi.setCandidateJourney(
        onboarding.tenantId,
        onboarding.clientOrganizationId,
        onboarding.configurationId,
        onboarding.applicationId,
        onboarding.userId,
        selectedId,
      );
    }
  };

  const { refetch: getIntegrationStateFaq } = useQuery({
    queryKey: ['getIntegrationStateFaq', tenantId],
    queryFn: () => aamBackendApi.getIntegrationStateFaq(tenantId),
    refetchOnMount: true,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      const integrationState = JSON.stringify(onboarding?.integrationState);
      const explanations: IntegrationStateFaq[] = [];

      data?.forEach((x) => {
        const replaceSpecialSymbols = (word: string) => {
          if (!word) return '';

          return word.replace(/\[|\]|\\|\//g, '');
        };

        const phraseWithoutChars = replaceSpecialSymbols(x.phrase);
        if (new RegExp(`\\b${phraseWithoutChars}\\b`).test(replaceSpecialSymbols(integrationState))) {
          explanations.push(x);
        }
      });

      setIntegrationStateFaq(explanations);
    },
  });

  const resetCandidateJourney = async () => {
    if (onboarding?.applicationId) {
      await aamBackendApi.resetCandidateJourney(
        onboarding.clientOrganizationId,
        onboarding.configurationId,
        onboarding.userId,
        onboarding.applicationId,
      );
      window.location.reload();
    }
    setResetJourneyUserModal(false);
  };

  useInterval(() => {
    if (!pollForDisyLink) return;

    const asyncCall = async () => {
      const { data: onb } = await refetchOnboarding();
      setTryCount((prev) => prev + 1);
      if (onb?.localMoUrl) {
        setLocalMoWasUpdated(true);
      }
    };
    asyncCall();
  }, FETCH_INTERVAL_MS);

  useEffect(() => {
    if (localMoUrlWasUpdated) {
      window.open(onboarding?.localMoUrl, '_blank');
      return;
    }

    if (tryCount > MAX_TRY_COUNT) {
      setShowRetriggerDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMoUrlWasUpdated, tryCount]);

  useEffect(() => {
    if (onboarding) {
      getIntegrationStateFaq();
    }
  }, [onboarding, getIntegrationStateFaq]);

  const renderHealthSeverityRating = (score: number) => {
    if (isNaN(score)) return;

    const { label, image, alt } = getSeverityDetails(score);
    return (
      <div className={styles.healthSeverityRating}>
        {label} &nbsp;
        <img src={image} alt={alt} />
      </div>
    );
  };

  return (
    <>
      <div className={styles.candidateInfo}>
        {user && onboarding && userProfile && (
          <>
            <Card className="tag-ds">
              <CardTop className={styles.header}>
                <div className={`status-tag_${onboarding.status === 'rejected' ? 'error' : 'success'}`}>
                  {t(`control-items:GENERAL.STATUS_FILTERS.${onboarding.status?.toUpperCase()}.label`)}
                </div>
                <Dropdown className={styles.menu}>
                  <DropdownButton>
                    <button className="button-card-icon">
                      <span className="material-icons">more_vert</span>
                    </button>
                  </DropdownButton>
                  <DropdownMenu>
                    <div className="dropdown-item-icon" onClick={() => setIntegrationModalVisible(true)}>
                      <span className="material-icons">merge_type</span>
                      {t('recruiter:GENERAL.CANDIDATE_DETAILS.integrationState')}
                    </div>{' '}
                    {hideProfileDeleteButtonRecruiter !== true && (
                      <div className="dropdown-item-icon" onClick={() => setDeleteUserModal(true)}>
                        <span className="material-icons">delete</span>
                        {t('recruiter:GENERAL.CANDIDATE_DELETION.deleteCandidate')}
                      </div>
                    )}
                    <div className="dropdown-item-icon" onClick={() => setActionHistory(true)}>
                      <span className="material-icons">pending_actions</span>
                      {t('recruiter:GENERAL.CANDIDATE_DETAILS.actionHistory')}
                    </div>{' '}
                    {onboardingChecked?.resetEnabled && (
                      <div
                        className={`dropdown-item-icon ${styles.witSeparator}`}
                        onClick={() => setResetJourneyUserModal(true)}
                      >
                        <span className="material-icons">repeat</span>
                        {t('recruiter:GENERAL.CANDIDATE_DELETION.resetJourney')}
                      </div>
                    )}
                  </DropdownMenu>
                </Dropdown>
                <div>
                  {frForbiddenDepartments.includes(currentBirthDepartment) && (
                    <Typography className={styles.warningMessage}>
                      {t('recruiter:GENERAL.CANDIDATE_DETAILS.birthDepartmentStateWarning')}
                    </Typography>
                  )}
                </div>
              </CardTop>
              <CardHeader>
                {candidateIsUnder18 && (
                  <h5 className={styles.warningMessage}>
                    {t('recruiter:GENERAL.CANDIDATE_DETAILS.candidateIsUnder18')}
                  </h5>
                )}
                {(userFlowWithCurrentStep?.recruiterAlerts || []).map((recruiterAlert: string) => (
                  <h5 key={recruiterAlert} className={styles.warningMessage}>
                    {t(recruiterAlert)}
                  </h5>
                ))}
                <h4>
                  {flowWrapper.getUserValueOrDefault('FIRST_NAME', 'string')}{' '}
                  {flowWrapper.getUserValueOrDefault('LAST_NAME', 'string')}
                </h4>
                {onboarding?.customData?.healthSeverityScore && (
                  <div className={styles.healthSeverity}>
                    Health severity rating
                    {renderHealthSeverityRating(onboarding?.customData?.healthSeverityScore)}
                  </div>
                )}
                {integrationStateError && (
                  <h5 style={{ color: 'red', fontWeight: 'bold' }}>
                    {t(`recruiter:GENERAL.CANDIDATE_DETAILS.integrationStateError`)}
                  </h5>
                )}
                {integrationStateError &&
                  integrationStateFaq?.map((item: IntegrationStateFaq, index) => (
                    <h6 key={index} style={{ color: 'red' }}>
                      {t(`integration-state-faq:${item.explanation}`)}
                    </h6>
                  ))}
              </CardHeader>
              <CardBody>
                <InformationList
                  userData={{
                    email: user.email,
                    phoneNumber: phoneNumber,
                    address: address,
                    client: client,
                    clientDisplayName: clientDisplayName,
                    onboardingApplicationId: onboarding.applicationId,
                    journey: journey,
                    selectedCandidateJourney: selectedCandidateJourney,
                    candidateJourneys: candidateJourneys,
                    onboardingStatus: onboarding.status,
                    selectedCandidateJourneyId: selectedCandidateJourneyId,
                  }}
                  onCandidateJourneySelect={onCandidateJourneySelect}
                />
              </CardBody>
              <CardFooter>
                {onboardingChecked?.amazonWarning && (
                  <h5 className={styles.warningMessage}>{t('recruiter:GENERAL.CANDIDATE_DETAILS.amazonWarning')}</h5>
                )}
                {visibleButton.unReject && (
                  <button onClick={() => unRejectUser()}>
                    {t(`recruiter:GENERAL.CANDIDATE_DELETION.unRejectCandidate`)}
                  </button>
                )}
                {visibleButton.reject && (
                  <button disabled={disableReject} className="button-secondary" onClick={() => setRejectPrompt(true)}>
                    {t(`recruiter:GENERAL.ONBOARDING_REJECTION.rejectCandidate`)}
                  </button>
                )}
                {hasHiringFeature ? (
                  <>
                    <button
                      disabled={!onboardingChecked?.hiringEnabled || isHireButtonDisabled}
                      onClick={() => {
                        setIsHireButtonDisabled(true);
                        hireCandidate();
                        createTelemetryAuthenticated({
                          applicationId: applicationId,
                          clientOrganizationId: clientOrganizationId,
                          configurationId: configurationId,
                          eventType: EventType.BUTTON_CLICKED,
                          tenantId: tenantId,
                          targetName: TargetName.RecruiterHire,
                          candidateId: userId,
                          actor: TelemetryActor.RECRUITER,
                        });
                      }}
                    >
                      {t(`recruiter:HIRING.HIRE.hireCandidate`)}
                    </button>
                  </>
                ) : (
                  <>
                    <button disabled={!onboardingChecked?.approveEnabled} onClick={() => approveCandidate()}>
                      {t(`recruiter:APPROVAL.APPROVE.approveCandidate`)}{' '}
                    </button>
                  </>
                )}
                {!!userProfile?.customData?.pdfExported && (
                  <button
                    className="button-secondary"
                    onClick={() => {
                      exportApprovedPDF(clientOrganizationId, configurationId, userId, applicationId);
                      exportAllDocumentsPDF(userId);
                    }}
                  >
                    {t(`recruiter:GENERAL.DOWNLOAD.downloadPDF`)}
                  </button>
                )}
              </CardFooter>
            </Card>
            {visibleButton.localSystem && (
              <Link
                text={t(`recruiter:HIRING.HIRE.go_to_local_system`)}
                onClick={() => window.open(onboarding?.localMoUrl)}
              />
            )}
            {pollForDisyLink && <LinkSkeleton pleaseWaitText={t(`recruiter:HIRING.HIRE.fetching-data-please-wait`)} />}
          </>
        )}
        {integrationModalVisible && (
          <IntegrationStateModal
            integrationState={onboardingErrorFiltered}
            isActive={integrationModalVisible}
            closeModal={() => setIntegrationModalVisible(false)}
          />
        )}
        {integrationErrorsModalVisible && (
          <IntegrationErrorsModal
            integrationState={onboardingErrorFiltered}
            isActive={integrationErrorsModalVisible}
            closeModal={() => setIntegrationErrorsModalVisible(false)}
          />
        )}
        {actionHistory && (
          <ActionHistory userId={userId} isActive={actionHistory} onClose={() => setActionHistory(false)} />
        )}
        <AdminRejectReasonPrompt
          clientOrganizationId={clientOrganizationId}
          configurationId={configurationId}
          userId={userId}
          applicationId={applicationId}
          onClose={() => setRejectPrompt(false)}
          onSuccess={() => {
            refetchUserProfile();
            refetchOnboarding();
            refetchUser();
            history.push('/oo/recruiter');
          }}
          isVisible={rejectPrompt}
        />
        <DeleteCandidateDialog
          onChangeSkipDeletionMail={() => setSkipDeletionMail(!skipDeletionMail)}
          skipDeletionMail={skipDeletionMail}
          open={deleteUserModal}
          onClose={() => setDeleteUserModal(false)}
          onDelete={() => {
            deleteUser({ sendDeletionMail: !skipDeletionMail });
          }}
          deleting={deleting}
        />
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={resetJourneyUserModal}
          onClose={() => setResetJourneyUserModal(false)}
          style={{ overflowY: 'visible' }}
          PaperProps={{
            style: {
              borderRadius: 8,
              width: '667px',
              height: '260px',
            },
          }}
        >
          <div className="tag-ds modal docs">
            <button className="modal-close-button" onClick={() => setResetJourneyUserModal(false)}>
              <span className="material-icons">close</span>
            </button>

            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <h4 className="modal-header">{t('recruiter:GENERAL.CANDIDATE_DELETION.resetJourneyUserMessage')}</h4>
              {/* <div className="modal-body">here we should add message</div> */}
            </Box>
            <div style={{ paddingTop: '12px' }}>
              <div className="modal-footer">
                <button className="button-secondary" onClick={() => setResetJourneyUserModal(false)}>
                  {t(`GENERAL.CANDIDATE_DELETION.dontReset`).toUpperCase()}
                </button>
                <button className="button-primary" onClick={() => resetCandidateJourney()}>
                  {t(`GENERAL.CANDIDATE_DELETION.reset`).toUpperCase()}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <RetriggerIntegrationDialog
          open={showRetriggerDialog}
          onClose={() => setShowRetriggerDialog(false)}
          onRetrigger={() => {
            setTryCount(0);
            setShowRetriggerDialog(false);
          }}
        />
      </div>
      <Notification
        icon={<span className="material-icons">info_outline</span>}
        success
        className={`tag-ds ${styles.notification}`}
        show={showHireNotification}
        onClose={() => setShowHireNotification(false)}
      >
        <NotificationBody>
          {t(`recruiter:APPROVAL.APPROVE.approvedForEmployment`, {
            name: userProfile?.firstName + ' ' + userProfile?.lastName,
          })}
        </NotificationBody>
      </Notification>
    </>
  );
};
