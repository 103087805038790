import React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material';

import { useT } from '../../../hooks/useT';
import { OOControlModelInterface } from '../../../interfaces';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './ValueDisplay.module.scss';

type ValueDisplayProps = {
  controlModel: OOControlModelInterface;
};

export const ValueDisplay: React.FC<ValueDisplayProps> = ({ controlModel }) => {
  const { t, translationsLoading } = useT('entry', 'approval', 'documents', 'hiring');
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(controlModel.name);
  let prefix = controlModel.label.split('.')[0].toLowerCase();
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  if (controlModel?.defaultValue !== undefined && (fieldProps.value === undefined || fieldProps.value === null)) {
    formik.setFieldValue(controlModel.name, controlModel.defaultValue);
  }
  if (translationsLoading) {
    return <FormControlLoader />;
  }
  return (
    <FormControl className={styles.valueDisplayContainer}>
      {controlModel.label ? (
        <Box pb={1} sx={{ paddingBottom: '0; !important' }}>
          <FormLabel>{t(`${prefix}:${controlModel.label}`)}:</FormLabel>
        </Box>
      ) : null}
      <div className="value-display">{controlModel.value || controlModel.defaultValue}</div>
      {controlModel.description && <FormHelperText>{t(`${prefix}:${controlModel.description}`)}</FormHelperText>}
    </FormControl>
  );
};
