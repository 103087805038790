import { Tenant } from '@hooks/useComponentMapping';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { tenantSlice } from '@store/slices/tenant.slice';

import { store } from '../store';
import { getUrlParameter } from './getUrlParameter';

type TenantInfo = {
  name?: string;
  alias: string;
  language?: string;
  languageNativeName?: string;
  countryId: string;
  countryCode: string;
  countryName?: string;
  domains: string[];
  oneTrustApplicationId: string;
};
interface TenantMap {
  [key: string]: TenantInfo;
}

interface TenantByInstance {
  [key: string]: string[];
}

export const tenantMap: TenantMap = {
  tid107: {
    name: 'Adecco France',
    alias: 'VMSS2hz4qGua3CdrBj73',
    countryId: 'a7c24067-b7dd-4159-a032-0a10d62ae4fb',
    countryCode: 'FR',
    countryName: 'France',
    language: 'French',
    languageNativeName: 'Français',
    domains: ['fr.amazondigital.adecco.com', 'fr.uat.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid108: {
    name: 'Uber',
    alias: 'wTw3jze2gwCfpL7exTcw',
    countryId: '03908c1c-b804-44ab-ad04-df1ad6e34b1d',
    countryCode: 'NL',
    countryName: 'Netherlands',
    language: 'Nederlands',
    domains: ['nl.amazondigital.adecco.com', 'nl.uat.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid109: {
    name: 'Adecco Germany',
    alias: 'PLRwT3Nabcg7g6LMzxZx',
    countryId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
    countryCode: 'DE',
    countryName: 'Germany',
    language: 'German',
    domains: ['de.amazondigital.adecco.com', 'de.uat.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid131: {
    name: 'DIS AG Germany',
    alias: 'cAukrpzneH97AXbTsW6q',
    countryId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
    countryCode: 'DE',
    countryName: 'Germany',
    language: 'German',
    domains: [
      'dev.myonboarding.dis-ag.com',
      'test.myonboarding.dis-ag.com',
      'uat.myonboarding.dis-ag.com',
      'myonboarding.dis-ag.com',
    ],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid115: {
    name: 'Adecco Italy',
    alias: 'RnMfkC9Y7CTubjnuq4tZ',
    countryId: '81289486-9d90-447e-8ad1-c4549337fb79',
    countryCode: 'IT',
    countryName: 'Italy',
    language: 'Italian',
    domains: ['it.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid184: {
    name: 'Adecco United Kingdom',
    alias: '2ms2fi767wM3KV60SvFR',
    countryId: '44d374d4-973c-4d21-b74c-3a1d101d938e',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    language: 'English',
    domains: ['uk.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid188: {
    name: 'Office Angels United Kingdom',
    alias: 'himv2TmOwuqNnKN5SEB8',
    countryId: '44d374d4-973c-4d21-b74c-3a1d101d938e',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    language: 'English',
    domains: ['uk.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid174: {
    name: 'Adecco Spain',
    alias: 'ZUPcGM3tDbbxzu4YEZbu',
    countryId: 'da69ac6a-fdbf-4b90-bb56-bfbd29b22ec0',
    countryCode: 'ES',
    countryName: 'Spain',
    language: 'Spanish',
    languageNativeName: 'Español',
    domains: ['es.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid113: {
    name: 'Adecco Belgium',
    alias: 'HjXwMSaVKaWSuhs9HkyM',
    countryId: '5bbd36ec-00c6-4cfc-a742-35d38ea9d960',
    countryCode: 'BE',
    countryName: 'Belgium',
    language: 'French',
    languageNativeName: 'Français',
    domains: ['be.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
  tid208: {
    name: 'Adecco Australia',
    alias: 'qMA7SXSS96svUjTNb4cR',
    countryId: 'e1ff999e-e8ba-4391-91ab-4b3eea929a0f',
    countryCode: 'AU',
    countryName: 'Australia',
    language: 'English',
    languageNativeName: 'English',
    domains: ['au.amazondigital.adecco.com'],
    oneTrustApplicationId: 'efd9175d-b9c0-4b44-988a-98db1be0fe36',
  },
};

const tenantByInstance: TenantByInstance = {
  au: [`tid208`],
  //add other instances here
};

export const getTenantByDomainInstance = (): TenantMap => {
  const domain: string = window.location.hostname.split('.').pop() || '';
  const allAvailableTenant: string[] = tenantByInstance[domain] || [];
  if (allAvailableTenant.length === 0) return tenantMap;
  const tenantMapByInstance: TenantMap = {};
  allAvailableTenant.forEach((item: string) => {
    tenantMapByInstance[item] = tenantMap[item];
  });
  return tenantMapByInstance;
};

export const getTenantByDomain = (): { tenantId: number; tenantAlias: string } | null => {
  const domain = window.location.hostname;
  const tid = Object.keys(tenantMap).find((key) => tenantMap[key].domains.includes(domain));
  if (tid) {
    const tenantId = parseInt(tid.replace('tid', ''), 10);
    return {
      tenantId,
      tenantAlias: tenantMap[`tid${tenantId}`]?.alias,
    };
  }
  return null;
};

export const getTenantInfo = (): { tenantId: number; tenantAlias: string } => {
  if (process.env.REACT_APP_CORDOVA_BUILD) {
    return {
      tenantId: parseInt(process.env.REACT_APP_TENANT_ID!, 10),
      tenantAlias: process.env.REACT_APP_TENANT_ALIAS!,
    };
  }
  if (getUrlParameter('tenantId')) {
    const tid = parseInt(getUrlParameter('tenantId'), 10);
    return {
      tenantId: tid,
      tenantAlias: tenantMap[`tid${tid}`]?.alias,
    };
  }
  if (localStorage.getItem('tenantId')) {
    const tid = parseInt(localStorage.getItem('tenantId')!, 10);
    return {
      tenantId: tid,
      tenantAlias: tenantMap[`tid${tid}`]?.alias,
    };
  }

  const tid = 109;
  return {
    tenantId: tid,
    tenantAlias: tenantMap[`tid${tid}`]?.alias,
  };
};

export const storeTenant = (tenantId: number) => {
  const tenantAlias = tenantMap['tid' + tenantId].alias;
  localStorage.setItem('tenantId', tenantId.toString());
  localStorage.setItem('tenantAlias', tenantAlias);

  store.dispatch(tenantSlice.actions.setTenant({ tenantId, tenantAlias }));
};

export const getLanguage = (tenantId: number): string => {
  const langFromStorage = localStorage.getItem('i18nextLng') || getTenantDefaultLanguage(tenantId);
  return (getTenantLanguageList(tenantId) as string[]).includes(langFromStorage)
    ? langFromStorage
    : getTenantDefaultLanguage(tenantId);
};

export const getClientOrganizationId = (): string | null => {
  if (localStorage.getItem('clientOrganizationId')) {
    return localStorage.getItem('clientOrganizationId')!;
  }
  return null;
};

export const getConfigurationId = (): string | null => {
  if (localStorage.getItem('selectedConfigurationId')) {
    return localStorage.getItem('selectedConfigurationId')!;
  }
  return null;
};

export const getClientOrganizationIdImport = (): string | null => {
  if (localStorage.getItem('importClientOrganizationId')) {
    return localStorage.getItem('importClientOrganizationId')!;
  }
  return null;
};

export const getApplicationId = (): string | null => {
  if (localStorage.getItem('applicationId')) {
    return localStorage.getItem('applicationId')!;
  }
  return null;
};

export const getRedirectionUrl = (): string | null => {
  if (localStorage.getItem('redirectionUrl')) {
    return localStorage.getItem('redirectionUrl')!;
  }
  return null;
};
export const setRedirectionUrl = (redirectionUrl: string): void => {
  localStorage.setItem('redirectionUrl', redirectionUrl || '');
};

export const getSelectedConfigurationId = (): string | null => {
  if (localStorage.getItem('selectedConfigurationId')) {
    return localStorage.getItem('selectedConfigurationId')!;
  }
  return null;
};

export const setSelectedConfigurationId = (id: string): void => {
  localStorage.setItem('selectedConfigurationId', id);
  store.dispatch(oneOnboardingSlice.actions.setConfigurationId(id));
};
export const storeClientOrganization = (clientOrganizationId: string) => {
  localStorage.setItem('clientOrganizationId', clientOrganizationId);
  store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId(clientOrganizationId));
};

export const getTenantDefaultLanguage = (tenantId: number) => {
  switch (tenantId) {
    case Tenant.Germany:
      return 'de';
    case Tenant.DISAGGermany:
      return 'de';
    case Tenant.France:
      return 'fr';
    case Tenant.UK:
    case Tenant.Australia:
      return 'en';
    case Tenant.Italy:
      return 'it';
    case Tenant.Spain:
      return 'es';
    case Tenant.Netherlands:
      return 'nl';
    case Tenant.Belgium:
      return 'nl';
    default:
      return 'en';
  }
};

export const getTenantLanguageList = (tenantId: number) => {
  switch (tenantId) {
    case Tenant.Germany:
      return ['de', 'en'];
    case Tenant.DISAGGermany:
      return ['de', 'en'];
    case Tenant.France:
      return ['fr', 'en'];
    case Tenant.UK:
    case Tenant.Australia:
      return ['en'];
    case Tenant.Italy:
      return ['it', 'en'];
    case Tenant.Spain:
      return ['es', 'en'];
    case Tenant.Netherlands:
      return ['nl', 'en'];
    case Tenant.Belgium:
      return ['fr', 'nl'];
    default:
      return ['en'];
  }
};

export const getTenantFallbackLanguage = (tenantId: number) => {
  switch (tenantId) {
    case Tenant.Belgium:
      return ['fr'];
    default:
      return ['en'];
  }
};

export const getTenantCountryCode = (tenantId: number) => {
  switch (tenantId) {
    case Tenant.Germany:
      return 'DE';
    case Tenant.DISAGGermany:
      return 'DE';
    case Tenant.France:
      return 'FR';
    case Tenant.UK:
      return 'UK';
    case Tenant.Italy:
      return 'IT';
    case Tenant.Spain:
      return 'ES';
    case Tenant.Netherlands:
      return 'NL';
    case Tenant.Belgium:
      return 'BE';
    case Tenant.Australia:
      return 'AU';
    default:
      return 'DE';
  }
};

export const getOneTrustApplicationId = (tenantId: number): string | undefined => {
  const tenant = tenantMap[`tid${tenantId}`];
  if (tenant) {
    return tenant.oneTrustApplicationId;
  }
};
