import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useRecruiterVerifiesWorkproInduction = (
  params: SidebarItemStateHandlerParams,
): RecruiterSidebarItemState => {
  const { flowWrapper, setPageStepContext, steps } = params;
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const completed = flowWrapper.isStepCompleted('WORKPRO_INDUCTION_VERIFICATION');
  const active = completed ? false : currentStep === 'WORKPRO_INDUCTION_VERIFICATION';
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'WORKPRO_INDUCTION_VERIFICATION');
      const page = step?.pages.find((x) => x.name === 'WORKPRO_INDUCTION_VERIFICATION');
      setPageStepContext({ step, page });
    },
  };
};
