import noop from 'lodash/noop';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useCandidateDetailsGlobal = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { flowWrapper } = params;
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const active = currentStep === 'CANDIDATE_DETAILS';
  const completed = active ? false : flowWrapper.isStepCompleted('CANDIDATE_DETAILS');
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: () => noop(),
  };
};
