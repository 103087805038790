import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useRecruiterApprovesCandidate = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { flowWrapper, onboarding } = params;
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const completed = currentStep === 'APPROVE' && onboarding?.status === 'approved';
  const active = completed ? false : currentStep === 'APPROVE';
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      return;
    },
  };
};
