import noop from 'lodash/noop';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useRecruiterUploadsDocuments = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { flowWrapper, steps, setPageStepContext } = params;
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const active = currentStep === 'RECRUITER_DOCUMENT_UPLOAD';
  const isAuCitizen =
    (steps.find((s) => s.name === 'CANDIDATE_DETAILS')?.pages as any[])
      ?.reduce((acc, itm: any) => [...acc, ...(itm.controls || [])], [])
      .find((itm: any) => itm.name === 'IS_AUSTRALIAN_RESIDENT')?.value === 'YES';
  let completed = active ? false : flowWrapper.isStepCompleted('RECRUITER_DOCUMENT_UPLOAD');
  if (!completed && flowWrapper.isStepCompleted('DOCUMENT_UPLOAD') && isAuCitizen) {
    completed = true;
  }
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: isAuCitizen
      ? () => noop()
      : () => {
          const step = steps.find((x) => x.name === 'RECRUITER_DOCUMENT_UPLOAD');
          setPageStepContext({ step, page: undefined });
        },
  };
};
