import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { TrashIconOutlined } from '@components/shared/customIcons/app/TrashIconOutlined';
import { Button, FormHelperText, Grid, Typography } from '@mui/material';

import { useFlowWrapper } from '../../hooks/useFlowWrapper';
import { mandatoryCondition, renderCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { Component, OOControlModelInterface } from '../../interfaces';
import { OOControlModel } from '../../models/ControlModel';
import { OOFormControl } from '../FormControl';
import { OOAccordion } from './Accordion/OOAccordion';
import { OOCard } from './Card/OOCard';

export type CardWithButtonsProps = {
  controlModel: OOControlModelInterface;
  hasPreview?: boolean;
  filterSubControls?: (c: OOControlModel) => boolean;
};

const getValue = (controlModel: OOControlModel[], fieldName?: string): string | undefined => {
  if (!fieldName) return '';
  return controlModel.find((control) => control.originalName === fieldName)?.value;
};

export const CardWithButtons: React.FC<CardWithButtonsProps> = ({ controlModel, filterSubControls, hasPreview }) => {
  const { t } = useT('entry', 'approval', 'documents');
  const [activeControlModel, setActiveControlModel] = useState<OOControlModel[] | undefined>();
  const [viewCardData, setViewCardData] = useState<OOControlModel[] | undefined>();
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const flowWrapper = useFlowWrapper();
  const formik = useFormikContext<Record<string, any>>();
  const validationKey = controlModel.name;
  const fieldMeta = formik.getFieldMeta(validationKey);
  const { error } = fieldMeta;
  const { subControls } = controlModel;
  const createNewControls = (controls: any, index: number) =>
    controls.map((item: any) => {
      const ooControl = new OOControlModel({
        ...item,
        name: OOControlModel.generateNameForSubControl(controlModel.name, index, item.originalName),
        value: flowWrapper?.getDefaultValue(item.type),
      });

      ooControl.parentFormIndex = index;
      ooControl.parentFormName = item.parentFormName;
      return ooControl;
    });

  const handleEdit = (subControl: OOControlModel[], index: number) => {
    setActiveControlModel(subControl);
    setActiveIndex(index);
  };

  const handleSave = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        const SKIP_FORWARD = true;
        const newValues = { ...formik.values, SKIP_FORWARD };
        formik.setValues(newValues);
        setActiveControlModel(undefined);
        setActiveIndex(undefined);
        formik.handleSubmit();
      }
    });
  };

  const handleDelete = (index?: number) => {
    if (index === undefined) return;
    if (Array.isArray(controlModel.subControls)) {
      const newSubControls = [...controlModel.subControls];
      newSubControls.splice(index, 1);
      controlModel.subControls = newSubControls as Array<Array<OOControlModel>>;

      const prefix = `ARRAY_ELEMENT:${controlModel.name}:`;
      const newValues: Record<string, any> = {};

      Object.keys(formik.values).forEach((key) => {
        if (key.startsWith(prefix)) {
          const parts = key.split(':');
          const currentIndex = parseInt(parts[2], 10);
          if (currentIndex === index) {
            delete newValues[key];
          } else {
            newValues[key] = formik.values[key];
          }
        } else {
          newValues[key] = formik.values[key];
        }
      });
      const SKIP_FORWARD = true;
      const payload = { ...newValues, SKIP_FORWARD };
      formik.setValues(payload);
      formik.handleSubmit();
      setActiveControlModel(undefined);
      setActiveIndex(undefined);
    }
  };
  const handleAdd = () => {
    if (Array.isArray(controlModel.subControls)) {
      if (!controlModel.arrayElementHeading) {
        return;
      }

      const firstSubControlPopulate =
        getValue(controlModel?.subControls[0] as OOControlModel[], controlModel.arrayElementHeading) !== undefined;
      const newItem = JSON.parse(JSON.stringify(controlModel.subControls[0] || []));
      const newIndex = firstSubControlPopulate ? controlModel.subControls.length : 0;
      const ooControlModels = createNewControls(newItem, newIndex);
      controlModel.subControls.push(ooControlModels);
      setActiveIndex(newIndex);
      const newValues: Record<string, any> = {};
      ooControlModels.forEach((item: any) => {
        newValues[item.name as string] = item.value;
      });

      setActiveControlModel(ooControlModels);
      if (flowWrapper) {
        formik.setValues({
          ...formik.values,
          ...newValues,
        });
      }
    }
  };
  const handleView = (subControl: OOControlModel[], index: number) => {
    const viewOnlySubControl = subControl.map((control) => {
      return new OOControlModel({
        ...control,
        isEditableCandidate: false,
        isEditableRecruiter: false,
      });
    });
    setViewCardData(viewOnlySubControl);
    setActiveIndex(index);
  };

  const showAddButton =
    controlModel.maxSubControls && controlModel.subControls
      ? controlModel.subControls.length < controlModel.maxSubControls
      : true;
  if (!filterSubControls || !subControls) {
    throw Error('Missing properties!');
  }
  const renderControls = (controlModel: OOControlModel[]) => {
    return controlModel
      ?.filter((c) => renderCondition(c, flowWrapper, formik.values))
      ?.filter((c) => filterSubControls?.(c))
      .map((control: OOControlModel) => {
        control.isMandatory = mandatoryCondition(control, flowWrapper, formik.values) ?? control.isMandatory;
        return control;
      })
      .map(
        (subControl: OOControlModel) =>
          subControl.component !== Component.SubCardWithButtons && (
            <Grid key={subControl.name} item xs={12} style={{ minWidth: '100%', marginTop: '12px' }}>
              <OOFormControl key={subControl.name} control={subControl} otherFormControls={controlModel} />
            </Grid>
          ),
      );
  };
  return (
    <div style={{ margin: 20 }}>
      {activeControlModel ? (
        <Grid item xs={12} style={{ minWidth: '100%', marginTop: '12px', height: 'calc(100% - 12px)' }}>
          {renderControls(activeControlModel)}
          <div style={{ display: 'flex', gap: 16, marginTop: '5rem' }}>
            <Button variant="outlined" color="secondary" onClick={() => handleDelete(activeIndex)}>
              {t('candidate_recruiter:GENERAL.GENERIC.delete')}
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSave}>
              {t('candidate_recruiter:GENERAL.GENERIC.save')}
            </Button>
          </div>
        </Grid>
      ) : (
        <>
          {(controlModel.subControls as Array<Array<OOControlModel>>).map((arrayControls, index) => (
            <div key={index}>
              <Grid key={index} item xs={12} style={{ minWidth: '100%', marginTop: '12px' }}>
                {getValue(arrayControls, controlModel.arrayElementHeading) && (
                  <OOCard
                    key={index}
                    headerText={getValue(arrayControls, controlModel.arrayElementHeading)}
                    footer={
                      <>
                        <div style={{ display: 'flex', gap: 16 }}>
                          <Button
                            variant="text"
                            onClick={() => handleEdit(arrayControls, index)}
                            color="secondary"
                            size="small"
                          >
                            {t('candidate_recruiter:GENERAL.GENERIC.edit')}
                          </Button>
                          <Button
                            variant="outlined"
                            endIcon={<TrashIconOutlined />}
                            onClick={() => handleDelete(index)}
                            color="secondary"
                            size="small"
                          >
                            {t('candidate_recruiter:GENERAL.GENERIC.delete')}
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <Typography fontSize={16} sx={{ margin: '15px 0px' }}>
                      {getValue(arrayControls, controlModel.arrayElementSubtext)}
                    </Typography>
                    {hasPreview && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          minWidth: '100%',
                          marginTop: '12px',
                          height: 'calc(100% - 12px)',
                          marginBottom: '12px',
                        }}
                      >
                        <OOAccordion
                          onClick={() => handleView(arrayControls, index)}
                          controlModel={controlModel}
                          defaultShow={activeIndex === index}
                          key={index}
                        >
                          {viewCardData && renderControls(viewCardData)}
                        </OOAccordion>
                      </Grid>
                    )}
                  </OOCard>
                )}
              </Grid>
            </div>
          ))}
          {showAddButton && (
            <Grid key={controlModel.name} item xs={12} style={{ minWidth: '100%', marginTop: '5rem' }}>
              <Button variant="contained" color="secondary" onClick={handleAdd}>
                {t('candidate_recruiter:GENERAL.GENERIC.addEmployment')}
              </Button>
            </Grid>
          )}

          {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </>
      )}
    </div>
  );
};
