import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { apiQuery } from '@store/apiQuery';
import { store } from '@store/index';

import { useConditionOnControlItems } from '../../hooks/useConditionOnControlItems';
import { editableCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OORadioGroup } from './RadioGroup/OORadioGroup';

export const DefaultRadioGroup: React.VoidFunctionComponent<OOControlModelInterface> = (props) => {
  const flowData = store.getState().oneOnboarding.flow;
  const formik = useFormikContext<Record<string, any>>();
  const { translationsLoading } = useT('entry', 'control-items');
  const { data, isFetching } = apiQuery.useGetMultiselectValuesQuery({
    name: props.controlHeaderName ? props.controlHeaderName : props.name.toLowerCase(),
  });

  const isRecruiter = window.location.pathname.includes('oo/recruiter');
  const isEditableFromProps = isRecruiter ? props.isEditableRecruiter : props.isEditableCandidate;

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const conditionalControlItemsData = useConditionOnControlItems(data);

  const isEditable = (editableCondition(props, flowWrapper, formik.values) || isEditableFromProps) ?? true;
  return (
    <OORadioGroup
      data={conditionalControlItemsData}
      validationKey={props.name}
      description={props.description}
      staticText={props?.staticText ?? ''}
      mandatory={props.isMandatory}
      loading={translationsLoading || isFetching}
      parameters={props.parameters}
      link={props.link}
      disabled={!isEditable}
      defaultValue={props?.defaultValue}
    />
  );
};
