import { FormikContextType } from 'formik';
import moment from 'moment';
import { store } from '@store/index';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';
import { OOStepModel } from '../models/StepModel';

export class StartDateBeforeAvailabilityDate implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  hasEmploymentGap: OOControlModelInterface[];
  employmentGapReason: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;
  private flowData: OOStepModel[];
  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = formControls?.filter((fc) => fc.name.includes('START_DATE_WARNING')) ?? [];
    this.hasEmploymentGap = formControls?.filter((fc) => fc.name.includes('HAS_EMPLOYMENT_GAP')) ?? [];
    this.employmentGapReason = formControls?.filter((fc) => fc.name.includes('EMPLOYMENT_GAP_REASON')) ?? [];
    this.allControls = formControls ?? [];
    this.formik = formik;
    this.flowData = store.getState().oneOnboarding.flow;
  }

  onChange = (startDate: Date) => {
    this.control.value = startDate;
    const endDate = this.allControls?.find(
      (control) =>
        control.name === 'END_DATE' ||
        control.name.includes('ADDRESS_TO') ||
        control.name.includes('EMPLOYMENT_HISTORY_TO'),
    );
    const isNotFirstEmployment =
      this.control.name.includes('EMPLOYMENT_HISTORY_FROM') && this.control.name.split(':')[2] !== '0';
    if (isNotFirstEmployment) {
      const currentEmploymentIndex = this.control.name.split(':')[2];
      const perviousEmploymentIndex = Number(currentEmploymentIndex) - 1;
      const perviousEmploymentEndDate = this.formik.values[
        `ARRAY_ELEMENT:EMPLOYMENT_HISTORY:${perviousEmploymentIndex}:EMPLOYMENT_HISTORY_TO`
      ];
      if (perviousEmploymentEndDate) {
        const hasEmploymentGap = this.hasEmploymentGap && this.hasEmploymentGap[0];
        const maxGapDays = hasEmploymentGap?.max || 21;
        const gap = moment(startDate).diff(moment(perviousEmploymentEndDate), 'days');
        if (gap >= maxGapDays) {
          this.formik.setFieldValue(hasEmploymentGap?.name, true);
        } else {
          this.formik.setFieldValue(hasEmploymentGap?.name, false);
          this.formik.setFieldValue(this.employmentGapReason?.[0]?.name, '');
        }
      }
    }

    if (endDate) {
      const today = moment(moment().format('YYYY-MM-DD'));
      const selectedDay = moment(moment(startDate).format('YYYY-MM-DD'));

      const diffDays = today.diff(selectedDay, 'days');
      endDate.minOffset = selectedDay.isAfter(today) ? Math.abs(diffDays) : -Math.abs(diffDays);
    }

    const availableFrom = this.flowData
      ?.find((step) => step.name === 'CANDIDATE_DETAILS')
      ?.pages.find((i) => i.name === 'GENERAL_INFO')
      ?.controls.find((c) => c.name === 'AVAILABLE_FROM');

    const firstRelevantControl = this.relevantControls && this.relevantControls[0];

    if (firstRelevantControl) {
      if (availableFrom && startDate) {
        const availableFromDateObj = moment(availableFrom.value).format('YYYY-MM-DD');
        firstRelevantControl.isVisibleRecruiter = moment(availableFromDateObj).isAfter(startDate);
      } else {
        firstRelevantControl.isVisibleRecruiter = false;
      }
    }
  };
}
