import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import { store } from '@store/index';

import { renderCondition } from '../hooks/useRenderCondition';
import {
  ControlType,
  OOControlModelInterface,
  OODocumentations,
  OONavigationPages,
  OOPages,
  OOSteps,
  OOStepsEnum,
} from '../interfaces';
import { OOControlModel } from '../models/ControlModel';
import { OOPageModel } from '../models/FormModel';
import { OOStepModel } from '../models/StepModel';
import { OOStepModelWithCompletionInfo } from '../pages/Dashboard';

export const checkJumpConditions = (conditions: any) => {
  return conditions.every((cnd: any) => {
    if (cnd.value === undefined && cnd.notValue === undefined) {
      return false;
    }
    return cnd.value !== undefined ? cnd.value === cnd.control.value : cnd.notValue !== cnd.control.value;
  });
};

export const checkPageConditions = (conditions: any) => {
  return conditions.every((cnd: any) => {
    if (cnd.value === undefined && cnd.notValue === undefined) {
      return false;
    }
    if (typeof cnd.control.value === 'object' && cnd.control.value?.length > -1) {
      return cnd.value !== undefined
        ? cnd.control.value.includes(cnd.value)
        : !cnd.control.value.includes(cnd.notValue);
    }
    return cnd.value !== undefined ? cnd.value === cnd.control.value : cnd.notValue !== cnd.control.value;
  });
};

export const getExculudedJumpConditionSteps = (steps: any[]) => {
  const exculudedJumpConditionSteps: string[] = [];
  let jumpToStepIndex;
  for (const [index, step] of steps.entries()) {
    if (jumpToStepIndex && index < jumpToStepIndex) {
      exculudedJumpConditionSteps.push(step.name);
      continue;
    }
    const { jumpTo, conditions } = step.jumpCondition || {};
    if (jumpTo && conditions && checkJumpConditions(conditions)) {
      jumpToStepIndex = steps.findIndex((itm) => itm.name === jumpTo);
    }
  }
  return exculudedJumpConditionSteps;
};

export const getCandidateSteps = (steps: any[]) => {
  const exculudedSteps = getExculudedJumpConditionSteps(steps);
  return steps.filter((s) => s.role.includes('candidate') && !exculudedSteps.includes(s.name));
};

export const getRecruiterSteps = (steps: any[], skipSteps: string[] = []) => {
  const exculudedSteps = getExculudedJumpConditionSteps(steps);
  return steps.filter((s) => !skipSteps.includes(s.name) && !exculudedSteps.includes(s.name));
};

export class OOFlowWrapper {
  public steps: OOStepModel[];
  private userData: any;
  constructor(steps: OOStepModel[], userData: any) {
    this.steps = steps;
    this.userData = userData;
  }

  public static create(stepsJson: any) {
    const steps = stepsJson.map((step: any) => {
      return OOStepModel.fromJson(step);
    });

    return new OOFlowWrapper(steps, {
      flowData: {
        currentStep: 0,
        currentForm: 0,
        latestStep: 0,
      },
      FIRST_NAME: '',
      TERMS_AND_CONDITIONS: true,
    });
  }

  public getStep(name: string) {
    return this.steps.find((s: OOStepModel) => s.name === name);
  }

  public getDocumentStep(name: string) {
    return name
      ? this.steps.find((s: OOStepModel) => s.name === name)
      : this.steps.find((s: OOStepModel) => s.documentations?.length > 0);
  }

  public getAllFeatureSteps(feature: string) {
    return this.steps.filter((s: OOStepModel) => s.feature === feature);
  }

  public getUserValueOrDefault(controlNames: string | string[], type: ControlType): any {
    let controlValue: any, controlDefaultValue: any;

    const searchControls = (controls: OOControlModel[]): void => {
      for (const control of controls) {
        const controlNameMatch = Array.isArray(controlNames)
          ? controlNames.find((name) => control.name === name || control.name?.split(':')?.pop() === name)
          : control.name === controlNames || control.name?.split(':')?.pop() === controlNames;

        if (controlNameMatch) {
          controlValue = control.value;
          controlDefaultValue = control.defaultValue;
          break;
        }

        if (control.subControls) {
          searchControls(control.subControls.flat() as Array<OOControlModel>);
        }
      }
    };

    this.steps.forEach((step: OOStepModel) => step.pages.forEach((page: OOPageModel) => searchControls(page.controls)));

    if (!isBoolean(controlValue) && !controlValue) {
      return this.getDefaultValue(type, controlDefaultValue);
    }
    return controlValue;
  }

  getDefaultValue(type: ControlType, controlDefaultValue?: any): any {
    switch (type) {
      case 'string':
        return '';
      case 'boolean':
        return controlDefaultValue === 'true';
      default:
        return null;
    }
  }

  public generateArrayValues = (values: Record<string, any>): Record<string, any> => {
    const mainValues = Object.keys(values).reduce((acc: any, name: string) => {
      if (!name.includes('ARRAY_ELEMENT:') && !name.includes('SKIP_FORWARD')) {
        acc[name] = values[name];
      }
      return acc;
    }, {});

    const filteredValues: { [key: string]: any } = {};
    Object.keys(values).forEach((name) => {
      if (!name.includes('ARRAY_ELEMENT:')) {
        return;
      }
      const [, controlName, index, subControlName] = name.split(':');
      const indexNumber = Number(index);
      filteredValues[controlName] = Array.isArray(filteredValues[controlName]) ? filteredValues[controlName] : [];

      while (filteredValues[controlName].length <= indexNumber) {
        filteredValues[controlName].push({});
      }

      filteredValues[controlName][indexNumber] = filteredValues[controlName][indexNumber] ?? {};
      filteredValues[controlName][indexNumber][subControlName] = values[name];
    });

    Object.keys(filteredValues).forEach((name) => {
      if (Array.isArray(filteredValues[name])) {
        filteredValues[name] = filteredValues[name].filter(
          (x: any) => !isEmpty(x) && !Object.values(x).every((value) => value === undefined),
        );
      }
    });

    return { ...mainValues, ...filteredValues };
  };

  public isFlowLastStep(stepName: OOSteps) {
    const stepIndex = this.steps.findIndex((s) => s.name === stepName)!;
    return stepIndex === this.steps.length - 1;
  }

  public getCurrentStep(stepName: OOSteps) {
    const stepIndex = this.steps.findIndex((s) => s.name === stepName)!;
    if (stepIndex === -1) {
      return null;
    }
    return this.steps[stepIndex];
  }

  public getCurrentCandidateStep(stepName: OOSteps) {
    const candidateSteps = getCandidateSteps(this.steps);
    const stepIndex = candidateSteps.findIndex((s) => s.name === stepName)!;
    if (stepIndex === -1 || stepIndex === candidateSteps.length - 1) {
      return undefined;
    }
    return candidateSteps[stepIndex];
  }

  public getNextCandidateStep(stepName: OOSteps) {
    const candidateSteps = getCandidateSteps(this.steps);
    const stepIndex = candidateSteps.findIndex((s) => s.name === stepName)!;
    if (stepIndex === -1) {
      return undefined;
    }
    const nextCandidateStepIndex = candidateSteps.findIndex((step, index) => index > stepIndex);
    return candidateSteps[nextCandidateStepIndex];
  }

  public getNextRecruiterStep(stepName: OOSteps) {
    const stepIndex = this.steps.findIndex((s) => s.name === stepName);
    if (stepIndex === -1) {
      return undefined;
    }
    const nextStep = this.steps[stepIndex + 1];
    const nextStepName =
      nextStep.name === OOStepsEnum.documentValidation
        ? OOStepsEnum.documentUpload
        : nextStep.name === OOStepsEnum.documentValidationTwo
        ? OOStepsEnum.documentUploadTwo
        : nextStep.name;

    return this.steps.find((s) => s.name === nextStepName);
  }

  public getLastCandidateStep() {
    const candidateSteps = getCandidateSteps(this.steps);
    return candidateSteps[candidateSteps.length - 1];
  }

  public findStepIndexByName(name: string) {
    return this.steps.findIndex((s) => s.name === name);
  }

  public isLastCandidateStepAvailable(lastCandidateStepName?: OOSteps, currentStepName?: OOSteps) {
    if (!lastCandidateStepName || !currentStepName) {
      return false;
    }
    return this.findStepIndexByName(lastCandidateStepName) <= this.findStepIndexByName(currentStepName);
  }

  public getPrevStep(stepName: OOSteps) {
    const stepIndex = this.findStepIndexByName(stepName);
    if (stepIndex === -1 || stepIndex === 0) {
      return null;
    }
    return this.steps[stepIndex - 1];
  }

  public getNextForm(stepName: OOSteps, formName: OOPages) {
    const flowData: OOStepModel[] = store.getState().oneOnboarding.flow;
    this.steps = flowData.map((step: any) => {
      return OOStepModel.fromJson(step);
    });
    const step = this.getStep(stepName)!;
    const forms = step.pages.filter((p) => !p.condition || checkPageConditions(p.condition));
    const index = forms.findIndex((form) => form.name === formName);
    if (index === -1 || index === forms.length - 1) {
      return null;
    }
    return forms[index + 1];
  }
  public getPrevForm(stepName: OOSteps, formName: OOPages) {
    const step = this.getStep(stepName)!;
    return step.getPreviousForm(formName);
  }
  public getNextStepAndForm(currentStepName: OOSteps, currentFormName: OOPages | OODocumentations | null) {
    const step = this.getStep(currentStepName);
    if (!step) {
      throw new Error('Step not found');
    }
    const nextForm = this.getNextForm(step.name, currentFormName as OOPages);
    if (nextForm) {
      return {
        step: step.name,
        form: nextForm.name,
      };
    }
    const nextStep = this.getNextCandidateStep(currentStepName);
    if (!nextStep) {
      return {
        step: 'END' as any,
        form: '' as any,
      };
    }
    return { step: null, form: null };
  }

  public getPrevStepAndForm(currentStepName: OOSteps, currentFormName: OOPages | null) {
    const step = this.getStep(currentStepName);
    if (!step) {
      throw new Error('Step not found');
    }
    if (step.getPreviousForm(currentFormName)) {
      return {
        step: step.name,
        form: step.getPreviousForm(currentFormName)!.name,
      };
    }
    const prevStep = this.getPrevStep(currentStepName);
    if (!prevStep) {
      return null;
    }
    if (prevStep.getLastForm()) {
      return {
        step: prevStep.name,
        form: prevStep.getLastForm()!.name,
      };
    }
    return { step: prevStep.name, form: null };
  }

  public getNavigationPages = () => {
    const pages: OONavigationPages[] = ['DASHBOARD'];
    let skipIfNot: string | undefined;
    getCandidateSteps(this.steps).forEach((step) => {
      // Skip IMPORT and REGISGTRATION steps
      if (['REGISTRATION'].includes(step.name)) {
        return;
      }
      if (skipIfNot && skipIfNot !== step.name) {
        return;
      } else if (skipIfNot) {
        skipIfNot = undefined;
      }
      //also skip page if it isLastPage = true
      if (
        (step.pages.length === 0 || (step.pages.length > 0 && step.pages[0]?.isLastPage)) &&
        (step.documentations.length > 0 || step.documentsForSignature.length > 0)
      ) {
        if (skipIfNot && skipIfNot !== step.name) {
          return;
        }
        pages.push(step.name as any);
      }

      for (const page of step.pages) {
        if (skipIfNot && skipIfNot !== step.name) {
          return;
        }
        if (page.condition?.length > 0) {
          if (!checkPageConditions(page.condition)) {
            continue;
          }
        }
        pages.push((step.name + '_' + page.name) as OONavigationPages);
        if (page.jumpCondition) {
          const { jumpTo, setCompleted, conditions } = page.jumpCondition;
          if (checkJumpConditions(conditions)) {
            const jumpStepIndex = this.steps.findIndex((s) => s.name === jumpTo);
            const jumpStep = this.steps[jumpStepIndex];
            if (!jumpStep) {
              throw new Error(`Step "${jumpTo}" not found`);
            }
            skipIfNot = jumpStep.name;
            if (setCompleted) {
              if (jumpStepIndex === this.steps.length - 1) {
                // set unmatchable step name to skip all steps
                skipIfNot = '--';
              } else {
                skipIfNot = this.steps[jumpStepIndex + 1].name;
              }
            }
          }
        }
      }
    });
    pages.push('END');
    return pages;
  };

  public getCurrentStepAndFormFromNavigationPage = (navigationPage: string) => {
    const { step: selectedStep, form: selectedForm } = store.getState().oneOnboarding;

    for (const step of this.steps) {
      if (
        (step.pages.length === 0 || (step.pages.length > 0 && step.pages[0]?.isLastPage)) &&
        (step.documentations.length > 0 || step.documentsForSignature.length > 0)
      ) {
        if (step.name === navigationPage) {
          return { step: step.name, stepLabel: step.label, form: '' };
        }
      }
      // For all other steps go through all pages and generate their names
      for (const page of step.pages) {
        if (navigationPage === step.name + '_' + page.name) {
          return { step: step.name, stepLabel: step.label, form: page.name };
        }
      }
    }
    if (!this.steps.map((s) => s.name).includes(navigationPage as any)) {
      return { step: selectedStep, form: selectedForm };
    }
    throw new Error('Navigation page not found');
  };

  public static getStepsWithCompletionInfo(): OOStepModelWithCompletionInfo[] {
    const userFlow: OOStepModel[] = store.getState().oneOnboarding.flow;
    const currentIndex = userFlow.findIndex((step) => step.name === store.getState().oneOnboarding.currentStep);
    return getCandidateSteps(
      userFlow.map((step, index) => {
        const isCompleted = index < currentIndex;
        const isCurrent = index === currentIndex;
        return { step: step, isCompleted, isCurrent };
      }),
    );
  }

  public static isStepCompleted(currentStep: OOSteps | OOStepsEnum): boolean {
    const step = this.getStepsWithCompletionInfo().find((data: any) => {
      return data.step.name === currentStep;
    });
    return !!step?.isCompleted;
  }
  public static isStepAvailable(stepName: OOSteps | OOStepsEnum): boolean {
    const currentStep = store.getState().oneOnboarding.currentStep;
    const userFlow: OOStepModel[] = store.getState().oneOnboarding.flow;
    const stepIndex = userFlow.findIndex((data: any) => data.name === stepName);
    const step = userFlow.find((data: any) => data.name === stepName);
    const currentStepIndex = userFlow.findIndex((data: any) => data.name === currentStep);
    return step?.skipStep || stepIndex <= currentStepIndex;
  }

  public static isStepValid(
    userFlowBySelectedConfiguration?: OOStepModel[],
    stepName?: OOSteps | OOStepsEnum,
  ): boolean {
    const userFlow: OOStepModel[] = userFlowBySelectedConfiguration ?? store.getState().oneOnboarding.flow;
    if (!userFlow.length) return true;
    const stepIndex = userFlow.findIndex((data: any) => data.name === stepName);
    return stepIndex >= 0;
  }

  public static isRecruiter = (): boolean => window.location.pathname.includes('oo/recruiter');

  public isControlEditable = (isEditableCandidate: boolean, isEditableRecruiter: boolean): boolean => {
    return OOFlowWrapper.isRecruiter() ? isEditableRecruiter : isEditableCandidate;
  };

  public static isPageAvailable(page: OOPages): boolean {
    const { currentStep, currentForm: currentPage } = store.getState().oneOnboarding;
    const userFlow: OOStepModel[] = store.getState().oneOnboarding.flow;
    const currentStepData = userFlow.find((data: OOStepModel) => {
      return data.name === currentStep;
    });
    if (!currentStepData) return false;
    const pageIndex = currentStepData.pages.findIndex((pageData: OOPageModel) => pageData.name === page);
    const currentPageIndex = currentStepData.pages.findIndex((pageData: OOPageModel) => pageData.name === currentPage);
    return pageIndex <= currentPageIndex;
  }

  public static prepareValues(controls: OOControlModelInterface[], values: Record<string, any>) {
    const flowData: OOStepModel[] = store.getState().oneOnboarding.flow;
    const flowWrapper = this.create(flowData);
    controls.forEach((c) => {
      if (!renderCondition(c, flowWrapper, values)) {
        delete values[c.name];
      }

      if (c.category === 'calculated') {
        delete values[c.name];
      }

      if (
        values[c.name] &&
        c.type === 'number' &&
        typeof values[c.name] === 'string' &&
        !isNaN(parseFloat(values[c.name]))
      ) {
        values[c.name] = parseFloat(values[c.name]);
      }

      if (values[c.name] && c.type === 'date') {
        values[c.name] = moment.utc(moment(values[c.name]).format('YYYY-MM-DD')).toDate();
      }
    });
  }

  public getDisabledFieldForDE = (control: OOControlModel, fw: OOFlowWrapper) => {
    const currentStep = store.getState().oneOnboarding.currentStep;
    const currentIndex = fw.steps.findIndex((s) => s.name === currentStep);
    const docUpload2Index = fw.steps.findIndex((s) => s.name === OOStepsEnum.documentUploadTwo);
    if (docUpload2Index !== -1 && currentIndex >= docUpload2Index) {
      if (control.label?.split('.')[1] === OOStepsEnum.feedback) {
        return control;
      } else {
        control.isEditableCandidate = false;
        control.isEditableRecruiter = false;
      }
    }
  };
  public isStepCompleted(step: OOSteps, page?: OOPages): boolean {
    const { oneOnboarding } = store.getState();
    const { flow } = oneOnboarding;
    if (flow.length && step) {
      const { currentStep } = oneOnboarding;
      const currentStepIndex = flow.findIndex((s: OOStepModel) => s.name === currentStep);
      if (currentStepIndex === -1) {
        return false;
      }
      const stepIndex = flow.findIndex((s: OOStepModel) => s.name === step);
      if (stepIndex === -1) {
        return false;
      }
      if (!page) {
        return stepIndex < currentStepIndex;
      } else {
        const { currentForm } = oneOnboarding;
        const currentPageIndex = flow[currentStepIndex].pages.findIndex((p: OOPageModel) => p.name === currentForm);
        const pageIndex = flow[stepIndex].pages.findIndex((p: OOPageModel) => p.name === page);
        return stepIndex < currentStepIndex || (stepIndex === currentStepIndex && pageIndex < currentPageIndex);
      }
    }

    return false;
  }

  public isPageCompleted(step: OOSteps, page: OOPages): boolean {
    const { oneOnboarding } = store.getState();
    const { flow } = oneOnboarding;
    const { currentStep, currentForm } = oneOnboarding;
    if (!currentStep && !currentForm) {
      return false;
    }
    const currentStepIndex = flow.findIndex((s: OOStepModel) => s.name === currentStep);
    if (currentStepIndex === -1) {
      return false;
    }
    const currentPageIndex = flow[currentStepIndex].pages.findIndex((p: OOPageModel) => p.name === currentForm);

    const stepIndex = flow.findIndex((s: OOStepModel) => s.name === step);
    if (stepIndex === -1) {
      return false;
    }
    const pageIndex = flow[stepIndex].pages.findIndex((p: OOPageModel) => p.name === page);
    if (pageIndex === -1) {
      return false;
    }
    return stepIndex < currentStepIndex || (stepIndex === currentStepIndex && pageIndex < currentPageIndex);
  }

  public getCurrentStepAndPage = () => {
    const { currentStep, currentForm } = store.getState().oneOnboarding;
    return { step: currentStep, page: currentForm };
  };
}
