import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ClosePageIcon } from '@components/shared/customIcons/ClosePageIcon';
import { useInjection } from '@context/inversify-context-provider';
import { OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OOFormControl } from '@features/oneOnboarding/controls/FormControl';
import { controlModelToValidationObject, parseValidationErrors } from '@features/oneOnboarding/helpers';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { ImagePreview } from '@features/oneOnboarding/pages/UploadDocument/ImagePreview';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { validateChosenFile } from '@helpers/fileUpload.helper';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { useDecodedParams } from '@hooks/useDecodedParams';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import DocumentTypePicker from './components/DocumentTypePicker';

import styles from './DocumentPages.module.scss';

interface State {
  [key: string]: {
    file?: File;
    data?: string | ArrayBuffer | null;
  };
}

const UploadDocument: React.FC<{ hasValidationStep: boolean }> = ({ hasValidationStep }) => {
  const [state, setState] = useState<State>({});
  const [partName, setPartName] = useState('');
  const [sizeLimit, setSizeLimit] = useState(10);
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  const {
    document,
    documentationModel,
    step,
    setCommand,
    setDocument,
    initialDocumentType,
    setInitialDocumentType,
  } = useContext(OODocumentContext);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { appSettings } = useSelector((state: RootState) => state.featureConfiguration)!;

  const documentStatus = document?.userDocument?.additionalData?.status;
  const flowData = store.getState().oneOnboarding.flow;

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { tenantId } = useSelector((state: RootState) => state.tenant);

  const aamBackendApi = useInjection(AamBackendApi);

  useEffect(() => {
    setInitialDocumentType(null);
  }, [setInitialDocumentType]);

  useMemo(async () => {
    const sizeLimit = await aamBackendApi.fetchUploadLimitSize(clientOrganizationId, configurationId);
    setSizeLimit(Number(sizeLimit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientOrganizationId, configurationId]);

  const onlyImagesAllowed = false;
  const stepName = step?.name;

  const { t } = useTranslation(['documents', 'recruiter', 'candidate_recruiter']);

  if (!document) {
    return <div>no document</div>;
  }

  const initialValues = document?.controls
    ? document.controls.reduce((acc: any, c: any) => {
        if (document?.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED) {
          acc[c.name] = undefined;
        } else {
          acc[c.name] = c.value;
        }
        return acc;
      }, {} as any)
    : [];

  const selectFile = (event: any, props: FormikProps<any>) => {
    const part = partName;
    const file = event.target.files[0]!;
    const reader = new FileReader();
    reader.onload = function (e) {
      props.setFieldValue(part, e.target!.result);
      props.validateField(part);
      const fileUrl = URL.createObjectURL(file);
      setState({ ...state, [part]: { ...state[part], file, data: fileUrl } });
    };
    reader.readAsDataURL(file);
  };

  const partValidations = document.parts.reduce((acc, p) => {
    if (p.isMandatory) {
      acc[p.name] = Yup.string().required(t('documents:validation.required'));
    } else {
      acc[p.name] = Yup.string();
    }
    return acc;
  }, {} as any);

  const validationSchema = Yup.object({
    ...partValidations,
    ...controlModelToValidationObject(document.controls, t, false, flowWrapper, 'documents'),
  });
  const onInvalidFileChoose = (validation: any) => {
    if (!validation.valid) {
      setTypeError(validation.typeError);
      setSizeError(validation.sizeError);
    }
  };

  const onSaveClick = async (data: any, files: any, actions: FormikHelpers<FormikValues>) => {
    try {
      const result = await aamBackendApi.updateUserFlow(
        clientOrganizationId,
        configurationId,
        userId,
        data,
        files,
        applicationId,
      );
      if (result.selectedConfiguration) {
        const flow = OOFlowWrapper.create(result?.selectedConfiguration?.flow);
        const step = flow.steps.filter((step) => step.name === data.currentStep);
        const documentations = step[0].documentations;
        if (initialDocumentType) {
          await aamBackendApi.removeDocument(clientOrganizationId, configurationId, userId, applicationId, {
            currentStep: stepName,
            currentPage: initialDocumentType,
          });
          setInitialDocumentType(null);
        }
        for (const documentation of documentations) {
          for (const document of documentation.documents) {
            if (document.name === data.currentPage) {
              if (hasValidationStep) {
                await aamBackendApi.updateDocument(tenantId, document.userDocument.id, {
                  additionalData: { status: OnboardingDocumentStatus.REVIEW },
                });
              } else {
                await aamBackendApi.updateDocument(tenantId, document.userDocument.id, {
                  additionalData: { status: OnboardingDocumentStatus.VERIFIED },
                });
              }
            }
          }
        }
      }

      queryClient.refetchQueries(['getUserFlow']);
      queryClient.refetchQueries(['getUserOOFlowDocumentUpload']);
      setCommand(undefined);
      setDocument(undefined);
    } catch (err) {
      const parsed = parseValidationErrors(t, err);
      if (parsed.errors) {
        actions.setErrors(parsed.errors);
      } else {
        dispatch(
          snackbarSlice.actions.showError({
            message: t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.error_documents'),
          }),
        );

        queryClient.refetchQueries(['getUserFlow']);
        queryClient.refetchQueries(['getUserOOFlowDocumentUpload']);
      }
    }
  };

  const onInvalidFilePopupClose = () => {
    setSizeError(false);
    setTypeError(false);
  };

  const earlyActiveStepName = () => {
    if (flowWrapper && documentationModel) {
      const documentUploadTwoStep = flowWrapper.getStep(OOStepsEnum.documentUploadTwo)!;
      const isDocumentInDocumentUploadTwo = documentUploadTwoStep?.documentations.find(
        (documentation) => documentation.name === documentationModel.name,
      );

      if (
        stepName === OOStepsEnum.documentUpload &&
        documentUploadTwoStep?.uploadEarly &&
        isDocumentInDocumentUploadTwo
      ) {
        return OOStepsEnum.documentUploadTwo;
      }
    }
    return undefined;
  };

  const isDocumentFamilyPickerVisible =
    (appSettings.documentFamilyPickerVisible &&
      documentStatus === OnboardingDocumentStatus.REJECTED &&
      documentationModel?.documents.length !== 1) ||
    initialDocumentType;

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          const partsNames = document.parts.map((part) => part.name);
          const files: any = {};
          const data: any = {};
          for (const [name, value] of Object.entries(values)) {
            if (partsNames.includes(name)) {
              files[name] = state[name].file;
            } else {
              data[name] = value;
            }
          }
          const sendData = {
            currentStep: earlyActiveStepName() ?? stepName,
            currentPage: document.name,
            data: data,
          };
          onSaveClick(sendData, files, actions);
        }}
      >
        {(props) => (
          <form encType="multipart/form-data" onSubmit={props.handleSubmit} style={{ flexGrow: 1, overflowY: 'auto' }}>
            <div className={styles.container}>
              <div className={styles.form}>
                {isDocumentFamilyPickerVisible && (
                  <Grid item xs={12} style={{ paddingBottom: '30px' }}>
                    <DocumentTypePicker />
                  </Grid>
                )}
                <Grid container spacing={3}>
                  {document.controls
                    .filter((c) => c.isVisibleRecruiter)
                    .sort((a, b) => {
                      if (a.sequence && b.sequence) {
                        return a.sequence - b.sequence;
                      }
                      return 0;
                    })
                    .map((control, i) => (
                      <Grid xs={12} item key={control.name + i}>
                        <OOFormControl control={control} />
                      </Grid>
                    ))}
                </Grid>
                <div className="tag-ds" style={{ marginTop: 41 }}>
                  <button className="" disabled={!props.isValid || !props.dirty} style={{ width: '100%' }}>
                    {t('candidate_recruiter:GENERAL.GENERIC.save')}
                  </button>
                </div>
              </div>
              <div className={styles.preview}>
                <div className={styles.uploadControls}>
                  {document.parts.map((part, i) => (
                    <Grid item xs={12} key={part.name + i} style={{ marginBottom: 8 }}>
                      <Grid container direction="column">
                        <Grid container item direction="row">
                          <Grid item style={{ width: '100%' }} className="tag-ds">
                            <label
                              htmlFor="btn-upload"
                              className="document-form__action-button-special document-upload-label"
                              style={{ color: 'white' }}
                            >
                              <input
                                accept={'image/*, application/pdf'}
                                id="btn-upload"
                                name={part.name}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(event) => {
                                  const [file] = event?.target?.files || [];
                                  const sizeLimitToMB = sizeLimit * 1000000;
                                  const fileValidation = validateChosenFile(107, file, {
                                    allowOnlyImages: onlyImagesAllowed,
                                    maxSize: sizeLimitToMB,
                                  });
                                  if (!fileValidation.valid) {
                                    onInvalidFileChoose(fileValidation);
                                    return;
                                  }
                                  return selectFile(event, props);
                                }}
                              />
                              <p className="caption" style={{ color: '#1C304B' }}>
                                {t(`documents:${part.label}`)}
                              </p>
                              <div
                                className="document-button-preview__title-container tag-ds"
                                onClick={() => setPartName(part.name)}
                              >
                                <Box onClick={() => setPartName(part.name)} display="flex" alignItems="center">
                                  <p className="caption">
                                    {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.addFiles')}
                                  </p>
                                  <FileUploadRoundedIcon />
                                </Box>
                              </div>
                            </label>
                          </Grid>
                          {state[part.name]?.data && (
                            <Grid item xs={12}>
                              <Box
                                className="document-button-preview__preview-container"
                                style={{ backgroundColor: 'White' }}
                              >
                                <ImagePreview state={state} part={part} />
                                <Box
                                  className="document-button-preview__icon"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      [part.name]: { ...state[part.name], file: undefined, data: null },
                                    });
                                    props.values[part.name] = undefined;
                                    props.validateForm();
                                  }}
                                >
                                  <CancelIcon />
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Box className="document-preview-dialog__container">
        <Dialog open={sizeError || typeError} onClose={onInvalidFilePopupClose} disablePortal>
          <ClosePageIcon onClick={onInvalidFilePopupClose} className="document-preview-dialog__close_icon" />
          {sizeError && (
            <Typography className="document-form__size-limit-error">
              {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.sizeLimit', { sizeLimit })}
            </Typography>
          )}
          {typeError && (
            <Typography className="document-form__size-limit-error">
              {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.typeLimit')}
            </Typography>
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default UploadDocument;
